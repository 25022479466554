import { Injectable } from '@angular/core';


export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  permisos?:{};
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

const menu2 = {
  clientes:0,
  propiedades:0,
  captaciones:0,
  herramientas:0,
  opciones:0,
  apps:0,
  personal:0,
  portada:0,
  secciones:0,
  guitiriz:0,
  imagenes:0
};

console.log(menu2);


            const MENUITEMS = [
              {
                state: 'clientes',
                name: 'Clientes',
                type: 'link',
                icon: 'bar_chart',
                permisos: menu2.clientes
              },
              {
                state: 'propiedades',
                name: 'Propiedades',
                type: 'link',
                icon: 'comment',
                permisos: menu2.propiedades
              },
              {
                state: 'captaciones',
                name: 'Captaciones',
                type: 'link',
                icon: 'list_alt',
                permisos: menu2.captaciones
              },
              {
                state: 'opciones',
                name: 'Opciones',
                type: 'sub',
                icon: 'stars',
                permisos: menu2.opciones,
                children: [
                  { state: 'preferencias', name: 'Preferencias', type: 'link' },
                  { state: 'acciones', name: 'Acciones', type: 'link' },
                  { state: 'tipo', name: 'Tipo', type: 'link' },
                  { state: 'estado', name: 'Estado', type: 'link' },
                  { state: 'localidades', name: 'Localidades', type: 'link' }
                ]
              },
              {
                state: 'personal',
                name: 'Personal',
                type: 'link',
                icon: 'calendar_view_day',
                permisos: menu2.personal
              },
              {
                state: 'portada',
                name: 'Portada',
                type: 'link',
                icon: 'list_alt',
                permisos: menu2.portada
              },
              {
                state: 'secciones',
                name: 'Secciones',
                type: 'link',
                icon: 'list_alt',
                permisos: menu2.secciones
              },
              {
                state: 'guitiriz',
                name: 'Guitiriz',
                type: 'link',
                icon: 'list_alt',
                permisos: menu2.guitiriz
              },
              {
                state: 'imagenes',
                name: 'Imágenes',
                type: 'link',
                icon: 'list_alt',
                permisos: menu2.imagenes
              }
            ];

// content_copy



@Injectable()
export class MenuItems {
  menu2 : {};
  getMenuitem(): Menu[] {
    
   

    return MENUITEMS;
  }
}
