import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { HomeComponent } from './home/home.component';


export const AppRoutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/authentication/login',
        pathMatch: 'full'
      },
      {
        path: 'home',
        redirectTo: '/dashboards/dashboard1',
        pathMatch: 'full'
      },
      {
        path: 'dashboards',
        loadChildren: './dashboards/dashboards.module#DashboardsModule'
      },
      {
        path: 'clientes',
        loadChildren:
          './clientes/clientes.module#ClientesComponentsModule'
      },
      {
        path: 'propiedades',
        loadChildren:
          './propiedades/propiedades.module#PropiedadesComponentsModule'
      },
      {
        path: 'secciones',
        loadChildren:
          './secciones/secciones.module#SeccionesComponentsModule'
      },
      {
        path: 'guitiriz',
        loadChildren:
          './guitiriz/guitiriz.module#GuitirizComponentsModule'
      },
      {
        path: 'portada',
        loadChildren:
          './imagenesportadas/imagenesportadas.module#ImagenesPortadasComponentsModule'
      },
      {
        path: 'captaciones',
        loadChildren:
          './captaciones/captaciones.module#CaptacionesComponentsModule'
      },
      {
        path: 'apps',
        loadChildren:
          './apps/apps.module#AppsComponentsModule'
      },
      {
        path: 'opciones',
        loadChildren:
          './opciones/opciones.module#OpcionesComponentsModule'
      },
      {
        path: 'imagenes',
        loadChildren:
          './imagenes/imagenes.module#ImagenesComponentsModule'
      },
      {
        path: 'personal',
        loadChildren:
          './personal/personal.module#PersonalComponentsModule'
      }
    ]
  },
  {
    path: '',
    component: AppBlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren:
          './authentication/authentication.module#AuthenticationModule'
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'authentication/404'
  }
];
